import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { FONT_WEIGHT } from '../Common';

export const TeamShieldContainer = styled.div.attrs((props) => ({
  className: props.className,
  ...props,
}))<{ $selectable?: boolean; $selected?: boolean; $small?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  width: 3.5rem;
  z-index: 0;

  ${(props) =>
    props.$small
      ? `${breakpoint('lg')`
       width: 4.5rem;
      `}`
      : `${breakpoint('lg')`
       width: 6.875rem;
      `}`}

  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    z-index: -1;
    content: '';
  }

  &::before {
    box-shadow: 0 0 0 2px #626266;
  }

  img {
    margin: 20% 0;
    max-width: 60%;
    max-height: 100%;
  }

  span {
    color: #eee;
    font-family: 'GothamNarrow';
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 1rem;
    text-align: center;
  }

  ${(props) =>
    props.$selectable
      ? `
   width: 5.75vw;
  min-height: 5.75vw;
  min-width: 70px;
  margin: 1rem 1.5rem;
  cursor: pointer;

  &::before,
  &::after {
    transition: box-shadow 0.3s;
  }

  &:hover {
    &::before {
      box-shadow: 0 0 0 4px #eeeeee;
    }

    span {
      font-weight: ${FONT_WEIGHT.Bold};;
    }
  }
  `
      : ''}

  ${(props) =>
    props.$selected
      ? `
 
  &::before {
    box-shadow: inset 0 0 0 2px #ffb800;
  }

  &:hover {
    &::before {
      box-shadow: 0 0 0 4px #eeeeee;
    }

    &::after {
      box-shadow: inset 0 0 0 4px #ffb800;
    }
  }

  span {
    color: #ffb800;
    font-weight: ${FONT_WEIGHT.Bold};;
  }
  `
      : ''}
`;
