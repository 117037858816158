import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { deviceInfoSelector } from '../../store/deviceInfo';
import { StatsConfigItem, tabsConfigSelector } from '../../store/msgStats';
import { GenericCarousel } from '../GenericCarousel/GenericCarousel';
import { GC_ALIGN } from '../GenericCarousel/GenericCarousel.types';
import useGenericCarousel from '../GenericCarousel/useGenericCarousel';
import { Loader } from '../Loader/Loader';
import { Selector } from '../Selector/Selector';
import { SelectorSize } from '../Selector/Selector.types';
import * as UI from './MsgStats.styles';
import { prepareStatsUrl } from './prepareStatsUrl';

interface Props {
  team: string;
  league: string;
}

export const MsgStats = ({ team, league }: Props) => {
  const { deviceId } = useSelector(deviceInfoSelector);
  const { props: carouselProps } = useGenericCarousel();
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabsConfig = useSelector(tabsConfigSelector);
  const activeTab = searchParams.get('stats');

  const onTabClickHandler = useCallback(
    (stats: StatsConfigItem) => () => {
      if (activeTab !== stats.hashtag) {
        setIsIframeLoading(true);
        setSearchParams(
          (prev) => {
            prev.set('stats', stats.hashtag);
            return prev;
          },
          { replace: true },
        );
      }
    },
    [activeTab, setSearchParams],
  );

  useEffect(() => {
    const selectedTab = tabsConfig.find((tab) => tab.hashtag === activeTab);

    if (!selectedTab) {
      setSearchParams(
        (prev) => {
          prev.set('stats', tabsConfig[0]?.hashtag);
          return prev;
        },
        { replace: true },
      );
    }
  }, [tabsConfig]);

  const selectedStats = useMemo(() => {
    return tabsConfig.find((tab) => tab.hashtag === activeTab);
  }, [tabsConfig, activeTab]);

  const iFrameUrl = useMemo(() => {
    if (!selectedStats?.url) {
      return '';
    }

    return prepareStatsUrl({
      deviceId,
      league,
      team,
      url: selectedStats.url,
    });
  }, [deviceId, selectedStats?.url, league, team]);

  const tabs = useMemo(() => {
    return tabsConfig.map((tab) => {
      return (
        <Selector
          key={tab.hashtag}
          size={SelectorSize.MediumWider}
          selected={tab.hashtag === activeTab}
          onClick={onTabClickHandler(tab)}
        >
          {tab.name}
        </Selector>
      );
    });
  }, [tabsConfig, activeTab, onTabClickHandler]);

  const onLoad = useCallback(() => {
    setIsIframeLoading(false);
  }, []);

  return (
    <>
      <GenericCarousel {...carouselProps} $align={GC_ALIGN.LEFT} $isFullWidth={true}>
        {tabs}
      </GenericCarousel>
      <UI.IFrameWrapper>
        {isIframeLoading && <Loader />}
        {iFrameUrl && <UI.IFrame src={iFrameUrl} onLoad={onLoad} />}
      </UI.IFrameWrapper>
    </>
  );
};
