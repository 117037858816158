import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName, RouteParam } from '../Constants/routes';
import { getRouteByName, getRouteByNameWithParams } from '../utils/routes';
import { useExtendedNavigate } from './useExtendedNavigate';

export const useHandleGoBackOnPlayer = (): VoidFunction => {
  const navigate = useExtendedNavigate();
  const location = useLocation();
  const previousLocationPathname = location?.state?.previousLocation;

  const defaultRedirectBackRoute =
    {
      [ROUTES[RouteName.ScheduleGamesPlayer]]: getRouteByNameWithParams(RouteName.Schedule, {
        [RouteParam.TabName]: 'games',
      }),
      [ROUTES[RouteName.ScheduleChannelsPlayer]]: getRouteByNameWithParams(RouteName.Schedule, {
        [RouteParam.TabName]: 'channels',
      }),
      [ROUTES[RouteName.HomePlayer]]: getRouteByName(RouteName.Home),
      [ROUTES[RouteName.TeamsPlayer]]: getRouteByName(RouteName.Teams),
    }[location.pathname] || '';

  const handleGoBackOnPlayer = useCallback(() => {
    if (previousLocationPathname || !defaultRedirectBackRoute) {
      navigate(previousLocationPathname);
      return;
    }
    navigate(defaultRedirectBackRoute);
  }, [defaultRedirectBackRoute, previousLocationPathname]);

  return handleGoBackOnPlayer;
};
