import { NavigateFunction } from 'react-router';

import { NavigationItemType } from '../Navigation.types';

export const navigationClickHandler = (
  type: NavigationItemType,
  path: string,
  navigate: NavigateFunction,
) => {
  switch (type) {
    case NavigationItemType.HOME: {
      navigate(path || '', { replace: true });
      return;
    }

    case NavigationItemType.LIVE: {
      navigate(path || '');
      return;
    }

    case NavigationItemType.SCHEDULE: {
      navigate(path || '');
      return;
    }

    case NavigationItemType.TEAMS: {
      navigate(path || '');
      return;
    }

    case NavigationItemType.SETTINGS: {
      navigate(path || '');
      return;
    }

    case NavigationItemType.ODDS: {
      navigate(path || '');
      return;
    }
  }
};
