import { PropsWithChildren } from 'react';

import { Layout } from '../components/Layout/Layout';
import { useGetCurrentPathPattern } from '../hooks/useGetCurrentPathPattern';
import { routesConfig } from './routesConfig';

export const RouteLayoutWrapper = ({ children }: PropsWithChildren) => {
  const pathPattern = useGetCurrentPathPattern();
  const routeSettings = routesConfig[pathPattern];
  if (!routeSettings || routeSettings.customLayout) {
    return <>{children}</>;
  }

  const {
    hasZipCodeValidation,
    isPrivate,
    noNavbar,
    noFooter,
    noContentPadding,
    forceNavbar,
  } = routeSettings;

  return (
    <Layout
      noNavbar={noNavbar || (!forceNavbar && !isPrivate)}
      noFooter={noFooter}
      noZipBar={!hasZipCodeValidation}
      noContentPadding={noContentPadding}
    >
      {children}
    </Layout>
  );
};
