import { ReactNode, useState } from 'react';

import { SimpleModal } from './SimpleModal';
import { ModalContext, ModalProps } from './SimpleModalContext';

export const SimpleModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalProps, setModalProps] = useState<ModalProps>({ isOpen: false, modalName: '' });

  const openModal = (params: ModalProps) => {
    setModalProps((props) => ({ ...props, autoClose: true, ...params, isOpen: true }));
  };

  const closeModal = () => {
    setModalProps({ isOpen: false, modalName: '' });
  };

  return (
    <ModalContext.Provider value={{ closeModal, openModal }}>
      <SimpleModal {...modalProps} />
      {children}
    </ModalContext.Provider>
  );
};
