export const scrollToPosition = (top: number, durationInMs?: number, onScrollEnd?: () => void) => {
  const duration = durationInMs || 5000;
  let startTime: number;
  const start = window.scrollY;

  function scrollAnimation(currentTime: number) {
    if (!startTime) {
      startTime = currentTime;
    }

    const timeElapsed = currentTime - startTime;
    const scrollProgress = Math.min(timeElapsed / duration, 1);

    const distance = Math.abs(start - top);
    const topPosition =
      start > top ? start - distance * scrollProgress : start + distance * scrollProgress;

    window.scrollTo({
      // eslint-disable-next-line
      // @ts-ignore
      behavior: 'instant',
      top: topPosition,
    });

    if (timeElapsed < duration) {
      requestAnimationFrame(scrollAnimation);
    } else {
      const isScrolling = localStorage.getItem('nextScrollWillOccurs') === 'true';

      onScrollEnd && !isScrolling && onScrollEnd();
    }
  }

  requestAnimationFrame(scrollAnimation);
};

export const scrollToElement = (
  element: HTMLElement | null,
  durationInMs?: number,
  onScrollEnd?: () => void,
) => {
  const elementTop = element?.getBoundingClientRect().top || 0;
  const start = window.scrollY;
  const duration = durationInMs || 1000;
  let startTime: number;

  function scrollAnimation(currentTime: number) {
    if (!startTime) {
      startTime = currentTime;
    }

    const timeElapsed = currentTime - startTime;
    const scrollProgress = Math.min(timeElapsed / duration, 1);

    const distance = Math.abs(start - elementTop);

    const topPosition =
      start > elementTop ? start - distance * scrollProgress : start + distance * scrollProgress;

    window.scrollTo({
      // eslint-disable-next-line
      // @ts-ignore
      behavior: 'instant',
      top: topPosition,
    });

    if (timeElapsed < duration) {
      requestAnimationFrame(scrollAnimation);
    } else {
      onScrollEnd && onScrollEnd();
    }
  }

  requestAnimationFrame(scrollAnimation);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallbackFunctionVariadic = (...args: any[]) => void;

export const throttleScroll = (
  cb: CallbackFunctionVariadic,
  delay: number,
): CallbackFunctionVariadic => {
  let wait = false;
  let storedArgs: unknown[] | null = null;

  function checkStoredArgs() {
    if (storedArgs == null) {
      localStorage.setItem('nextScrollWillOccurs', 'false');
      wait = false;
    } else {
      cb(...storedArgs);
      storedArgs = null;
      setTimeout(checkStoredArgs, delay);
    }
  }

  return (...args: unknown[]) => {
    if (wait) {
      localStorage.setItem('nextScrollWillOccurs', 'true');
      storedArgs = args;
      return;
    }

    cb(...args);
    wait = true;
    setTimeout(checkStoredArgs, delay);
  };
};
