import { createContext, useContext } from 'react';

export type ModalButtonProps = {
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  destination?: string;
};

export type ModalProps = {
  content?: React.ReactNode;
  isOpen?: boolean;
  title?: string;
  description?: string | JSX.Element;
  acceptButton?: ModalButtonProps;
  cancelButton?: ModalButtonProps;
  centered?: boolean;
  autoClose?: boolean;
  reverseButtonOnMobile?: boolean;
  modalName: string;
};
export type OpenModal = (params: ModalProps) => void;

export type ModalContextValue = {
  closeModal: () => void;
  openModal: OpenModal;
};

export const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);

export const useSimpleModalContext = () => useContext(ModalContext);
