import { PlaybackError } from '@msgn/fl-module/fl-analytics';
import { createComposablePlayer } from '@msgn/fl-module/fl-player';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  FLLocalPlayer,
  FLPlatformAsset,
  PlatformAuthorizer,
  PlaybackMode,
  PlayerHandler,
  Video,
  flpPlayerApi,
} from '../../api';
import { ProductServiceTypeEnum } from '../../api/authApi/authApi.types';
import { flpOnErrorAdapter } from '../../api/playerApi/eventAdapters/flpOnErrorAdapter';
import { useConvivaContext } from '../../components/ConvivaProvider/ConvivaProvider';
import { useDataZoomContext } from '../../components/DataZoomProvider/context';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { Log } from '../../helpers/CustomLogger';
import { getPlaybackErrorPayload } from '../../helpers/getPlaybackErrorPayload';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { playerActions } from '../../store/player';
import { setPlayerError } from '../../store/player/player.thunks';
import { AppDispatch } from '../../store/store';
import { selectedVideoSelector } from '../../store/videos';
import { buildDataZoomContentParams } from '../../utils/data-zoom/buildDataZoomContentParams';

const NETWORK_ERROR_CODES = /^41[0-9]{4}/g;

export const getDummyPlayerHandler = () =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({
    currentTime: undefined,
    getActiveTracks: () => [],
    getAllTracks: () => [],
    isInvalidPlayer: true,
    isLive: false,
    pause: () => void 0,
    play: () => void 0,
    playbackRate: 1,
    seek: () => void 0,
    seekableRange: () => ({ end: 0, start: 0 }),
    selectTrack: () => void 0,
    // eslint-disable-next-line
    stop: async (error?: PlaybackError) => {
      // TODO eslint warning fix
    },
    subscribe: () => void 0,
    unsubscribe: () => void 0,
  } as PlayerHandler);

const dummyContent: Video = {
  cid: '',
  contentType: undefined,
  description: '',
  endDate: '',
  externalId: '',
  id: '',
  isFree: false,
  isLive: true,
  playbackContentType: undefined,
  productType: ProductServiceTypeEnum.SVOD,
  rawData: {},
  startDate: '',
  title: '',
};

export const useFLPlayerHandler = (
  {
    asset,
  }: {
    asset: FLPlatformAsset | undefined;
  },
  platformAuthorizer: PlatformAuthorizer,
  loadAsset: VoidFunction,
) => {
  const ref = useRef<HTMLVideoElement>(null);
  const fullscreenElementRef = useRef<HTMLDivElement>(null);
  const { contentUrl, licenseUrl } = asset || {};
  const { deviceId } = useSelector(deviceInfoSelector);
  const { playerConfiguration } = useEnv();
  const [playerRestartAttempts, setPlayerRestartAttempts] = useState(
    playerConfiguration.playerRestartAttempts,
  );
  const [localPlayer, setLocalPlayer] = useState(getDummyPlayerHandler());
  const [errorTimestamp, setErrorTimestamp] = useState<number | undefined>(undefined);
  const [player, setPlayer] = useState(localPlayer);
  const dispatch: AppDispatch = useDispatch();
  const [networkError, setNetworkError] = useState<boolean>(false);
  const isLocalPlayer = player === localPlayer;
  const selectedVideo = useSelector(selectedVideoSelector);
  const currentLocation = useLocation();
  const { convivaSession } = useConvivaContext();

  useEffect(() => {
    setPlayerRestartAttempts(playerConfiguration.playerRestartAttempts);
  }, []);

  const { setPlaybackSession, appSession } = useDataZoomContext();
  const { state } = currentLocation;

  const playbackSession = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!appSession?.reporter?.initialized) {
      return null;
    }

    const content = buildDataZoomContentParams(selectedVideo || dummyContent);
    const playbackSource = state?.container?.containerSource;
    const applicationContainer = {
      id: state?.container?.containerId,
      name: state?.container?.containerName,
    };
    return appSession.createPlaybackSession(content, playbackSource, applicationContainer);
  };

  const initDataZoomPlayer = (playerInstance: FLLocalPlayer) => {
    const playBackSessionInstance = playbackSession();
    if (!playerInstance.isInvalidPlayer && playBackSessionInstance) {
      try {
        playBackSessionInstance?.attachPlayer(playerInstance);
        playBackSessionInstance?.start();
        setPlaybackSession(playBackSessionInstance);
      } catch (e) {
        Log.log('initDataZoomPlayer error', e);
      }
    }
  };

  useEffect(() => {
    dispatch(playerActions.setIsLocalPlayer(isLocalPlayer));
  }, [isLocalPlayer]);

  const clearNetworkError = useCallback(() => {
    setNetworkError(false);
    dispatch(setPlayerError(undefined));
  }, []);

  const {
    HEARTBEAT_URL,
    HEARTBEAT_MAX_ALLOWED_FAILURES,
    STREAMCONCURRENCY_URL,
    HEARTBEAT_INTERVAL_SYNC,
    IS_PLAYER_IN_STORYBOOK_MODE,
    PLAYER_LOGGER,
  } = useEnv();

  const createPlayer = useCallback(
    (restartOnError = false, initialPlaybackTimestamp: number | undefined = undefined) => {
      if (ref.current && asset) {
        player
          .stop()
          .then(() => {
            const { start } = player.seekableRange();
            let initialPlaybackTime = undefined;
            if (asset.livePlaybackMode === PlaybackMode.RESTART) {
              initialPlaybackTime = start;
            } else if (restartOnError) {
              initialPlaybackTime = errorTimestamp ?? initialPlaybackTimestamp;
            }

            const newPlayer = flpPlayerApi.createPlayerHandler({
              asset,
              initialPlaybackTime,
              playerLogger: PLAYER_LOGGER,
              ref,
            });
            convivaSession?.attachPlayer(newPlayer);

            if (!IS_PLAYER_IN_STORYBOOK_MODE) {
              const heartbeatToken = asset?.heartbeatToken;

              const heartbeatManager = flpPlayerApi.createHeartbeatManager({
                asset,
                deviceId,
                heartbeatIntervalSync: HEARTBEAT_INTERVAL_SYNC,
                heartbeatMaxAllowedFailures: HEARTBEAT_MAX_ALLOWED_FAILURES,
                heartbeatToken,
                heartbeatUrl: HEARTBEAT_URL,
                platformAuthorizer,
                streamConcurrencyUrl: STREAMCONCURRENCY_URL,
              });

              const composablePlayer = createComposablePlayer(newPlayer);
              composablePlayer.addStateChangeStep(heartbeatManager.processPlayerStateChange);
              composablePlayer.addHeartBeatStep(heartbeatManager.processHeartbeatChange);
            }
            (
              newPlayer as PlayerHandler & {
                id: number;
              }
            ).id = Math.random();

            if (isLocalPlayer) {
              (
                newPlayer as {
                  isInvalidPlayer: boolean;
                }
              ).isInvalidPlayer = false;
            }
            setPlayer(newPlayer);
            setLocalPlayer(newPlayer);
            initDataZoomPlayer(newPlayer);
          })
          .catch((error: string) => {
            Log.error(error);
          });
      }
    },
    [contentUrl, licenseUrl, ref, errorTimestamp],
  );

  useEffect(() => {
    if (ref.current && asset) {
      createPlayer(
        playerRestartAttempts < playerConfiguration.playerRestartAttempts,
        errorTimestamp,
      );
    }
  }, [contentUrl, licenseUrl, ref]);

  useEffect(() => {
    if (player) {
      const progressUpdateCallback = () => {
        setPlayerRestartAttempts(playerConfiguration.playerRestartAttempts);
      };
      player.subscribe('progressupdate', progressUpdateCallback);
      return () => {
        player.unsubscribe('progressupdate', progressUpdateCallback);
      };
    }
  }, [player]);

  useEffect(() => {
    return flpOnErrorAdapter(player, (error) => {
      const { hexErrorCode: errorCode, errorMessage } = error;
      try {
        Log.error('player error', {
          currentTime: player.currentTime,
          errorCode,
          errorMessage,
          playerRestartAttempts,
        });
        playbackSession()?.stop(
          getPlaybackErrorPayload({
            asset,
            error,
            errorCode: `0x${errorCode}`,
            errorMessage,
          }),
        );
        if (NETWORK_ERROR_CODES.test(errorCode)) {
          setNetworkError(true);
        }

        if (
          playerConfiguration.playerRestartErrorCodes.includes(`0x${errorCode}`) &&
          playerRestartAttempts > 0
        ) {
          Log.log(
            'attempt player restart on buffer timeout with playerRestartAttempts: ',
            playerRestartAttempts,
          );
          setPlaybackSession(null);
          // reduce number of retries
          setPlayerRestartAttempts(playerRestartAttempts - 1);
          // set playback time for restart due to how useEffect works it sometimes was 0 so we have to keep an external value in useState
          const initialPlaybackTime =
            player.currentTime !== 0 ? player.currentTime : errorTimestamp;
          setErrorTimestamp(initialPlaybackTime);
          loadAsset();
        } else {
          dispatch(
            setPlayerError({
              errorCode: errorCode,
              errorMessage: errorMessage,
            }),
          );
        }
      } catch (e) {
        Log.log('Unexpected error occurred', e);
      }
    });
  }, [player, createPlayer, playerRestartAttempts, errorTimestamp]);

  return {
    clearNetworkError,
    fullscreenElementRef,
    localPlayer,
    networkError,
    player,
    ref,
    setPlayer,
  };
};

export default useFLPlayerHandler;
