import { convivaAppTracker, setUserId } from '@convivainc/conviva-js-appanalytics';
import {
  LinkClickTrackingPlugin,
  enableButtonClickTracking,
  enableLinkClickTracking,
} from '@convivainc/conviva-js-appanalytics-click-tracking';
import {
  ErrorTrackingPlugin,
  enableErrorTracking,
} from '@convivainc/conviva-js-appanalytics-error-tracking';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import packageJson from '../../package.json';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { userDataSelector } from '../store/userData';

export const useConvivaEco = () => {
  const { conviva } = useEnv();
  const user = useSelector(userDataSelector);

  useEffect(() => {
    if (conviva.ecoIntegrationIsEnabled) {
      convivaAppTracker({
        appId: 'MSG+ (WEB)',
        appVersion: packageJson.version,
        contexts: {
          performanceTiming: true,
        },
        convivaCustomerKey: conviva.key,
        plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
      });
    }

    enableLinkClickTracking();
    enableButtonClickTracking();
    enableErrorTracking();
  }, []);

  useEffect(() => {
    setUserId(user.cpCustomerID || 'guest');
  }, [user.cpCustomerID]);
};
