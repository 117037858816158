import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { authActions } from '../../store/auth';
import { AppDispatch } from '../../store/store';
import { resetPassword, userDataSelector } from '../../store/userData';
import { getScreenName } from '../../utils/getScreenName';
import { ResetPasswordFormFields, resetPasswordSchema } from '../../utils/validations';
import { AuthForm } from '../AuthForm/AuthForm';
import { AuthFormFields } from '../AuthForm/AuthForm.types';
import { AuthPageWrapper } from '../AuthPageWrapper/AuthPageWrapper';

export const ResetPassword = () => {
  const t = useTranslations();
  const navigate = useExtendedNavigate();
  const [userToken, setUserToken] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const { error, resetPasswordLoaded } = useSelector(userDataSelector);
  const [params] = useSearchParams();
  const dispatch: AppDispatch = useDispatch();

  const formFields = {
    [ResetPasswordFormFields.ConfirmNewPassword]: {
      detailedError: false,
      errorOrder: 0,
      order: 1,
      placeholder: t.resetPassword_ConfirmPasswordPlaceholder,
      type: 'password',
    },
    [ResetPasswordFormFields.NewPassword]: {
      detailedError: true,
      errorOrder: 1,
      order: 0,
      placeholder: t.resetPassword_newPasswordPlaceholder,
      type: 'password',
    },
  };

  const onSuccess = ({ newPassword }: AuthFormFields) => {
    const data = {
      email,
      password: newPassword,
      userToken,
    };
    buttonClicked({
      location: 'Module: Reset Password',
      path: window.location.pathname,
      screen: getScreenName(),
      text: `CTA: ${t.resetPassword_resetButton}`,
      type: 'Button',
      url: window.location.href,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resetPassword(data));
  };

  useEffect(() => {
    setUserToken(params.get('resetPasswordToken'));
    setEmail(params.get('email'));
  }, [params]);

  useEffect(() => {
    if (!error && resetPasswordLoaded) {
      navigate('/reset-password-done', { replace: true });
    }
  }, [error, resetPasswordLoaded]);

  return (
    <AuthPageWrapper
      screen='Reset Password'
      pathname={ROUTES[RouteName.ResetPassword]}
      location='resetPassword'
    >
      <AuthForm
        onSuccess={onSuccess}
        validationSchema={resetPasswordSchema}
        error={error}
        cleanupErrorAction={authActions.cleanUpErrorLogin}
        formFields={formFields}
        submitButtonText={t.resetPassword_resetButton}
        displayBulletsForErrors
        secondaryButton={{
          route: RouteName.Login,
          text: t.resetPassword_cancelButton,
        }}
      />
    </AuthPageWrapper>
  );
};

ResetPassword.displayName = 'ResetPassword';
