import { CastPlayer } from '@msgn/fl-module/fl-chrome-sender';
import { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { CastManager, FLCastPlayer, PlayerState, quickplayCmsApi } from '../../api';
import { Platform } from '../../api/mParticlesApi/constants';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { PlayerHandlerContext } from '../../components/Player/Player';
import { prepareFlAnalyticsData } from '../../components/playerUI/utils';
import { lastSubscriptionSelector } from '../../store/activeSubscriptions';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { playerActions } from '../../store/player';
import { setPlayerError } from '../../store/player/player.thunks';
import { AppDispatch } from '../../store/store';
import { selectedVideoSelector } from '../../store/videos';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { buildCastVelocityControl, buildCastVolumeControl } from '../../utils/flp';
import { useDataZoom } from '../useDataZoom';
import { useTranslations } from '../useTranslations';
import { useVideos } from '../useVideos';
import useFLPCastManager from './useFLPCastManager';
import { getAuthorizePlaybackParams } from './useFLPPlatformAsset';
import { useSSAIHeaders } from './useSSAIHeaders';
import { getPPGHeaders, getTveHeaders } from './utils/headers';

const CAST_METADATATYPE = 1;
const CAST_IMAGE_SIZE = '0-16x9';
const CAST_IMAGE_WIDTH = 1960;

export const useFLPCastSession = () => {
  const dispatch: AppDispatch = useDispatch();
  const [castDeviceName, setCastDeviceName] = useState<string>();
  const {
    assetState,
    localPlayer,
    setPlayer,
    player,
    platformAuthorizer,
    platformClient,
    volumeControlState,
    velocityControlState,
    playerErrorActionState,
  } = useContext(PlayerHandlerContext);

  const { restoreLocalVolumenControl, setVolumeControl } = volumeControlState;
  const { restoreLocalVelocityControl, setVelocityControl } = velocityControlState;
  const { restoreLocalPlayerErrorAction, setPlayerErrorAction } = playerErrorActionState;
  const { getStreamTitle } = useVideos();
  const selectedVideo = useSelector(selectedVideoSelector);

  const t = useTranslations();

  const { THUMB_IMAGE_URL } = useEnv();
  const lastSubscription = useSelector(lastSubscriptionSelector);
  const { userInfo } = useDataZoom();

  const { deviceId } = useSelector(deviceInfoSelector);
  const { zipCode } = useSelector(zoneInfoStateSelector);

  const onCastSessionConnected = useCallback(
    (castPlayer: FLCastPlayer, castManager: CastManager) => {
      if (!selectedVideo) return;

      const castAsset = {
        ...getAuthorizePlaybackParams(selectedVideo),
        ...useSSAIHeaders(selectedVideo, deviceId, zipCode, lastSubscription, Platform.Chromecast),
      };
      localPlayer.pause();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const assetId = castAsset.mediaID;
      const title = getStreamTitle();

      const imageUrl = quickplayCmsApi.getImageResizeUrl({
        assetId,
        assetSize: CAST_IMAGE_SIZE,
        format: 'jpg',
        thumbImageUrl: THUMB_IMAGE_URL,
        width: CAST_IMAGE_WIDTH,
      });
      const flAnalyticsData = prepareFlAnalyticsData(selectedVideo, userInfo);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      castPlayer.loadMedia(castAsset, {
        flAnalyticsData,
        headers: {
          ['X-Tracking-Id']: uuid(),
          ...getTveHeaders(assetState?.tveToken),
          ...getPPGHeaders(assetState?.tvodToken),
        },
        initialPlaybackTime: localPlayer.currentTime,
        metadata: {
          images: [{ url: imageUrl }],
          metadataType: CAST_METADATATYPE,
          title,
        },
      });
      setPlayer(castPlayer);

      const castDevice = castManager.castSessionManager.getCastDevice();
      const castDeviceName = castDevice.friendlyName;
      setCastDeviceName(castDeviceName);
      setVolumeControl(() => buildCastVolumeControl({ castPlayer }));
      setVelocityControl(() => buildCastVelocityControl());
      setPlayerErrorAction(() => () => {
        dispatch(setPlayerError(undefined));
        castManager.castContext.endCurrentSession(true);
      });
    },
    [
      assetState,
      lastSubscription,
      localPlayer,
      selectedVideo,
      userInfo,
      deviceId,
      zipCode,
      setVolumeControl,
      setVelocityControl,
      setPlayer,
      setCastDeviceName,
      setPlayerErrorAction,
      getStreamTitle,
    ],
  );

  const onCastSessionEnded = useCallback(
    (castPlayer: FLCastPlayer) => {
      const currentTime = castPlayer?.isLive ? undefined : castPlayer?.currentTime;
      if (currentTime !== undefined) {
        localPlayer.seek(currentTime);
      }
      setPlayer(localPlayer);
      localPlayer.play();

      setCastDeviceName(undefined);
      dispatch(playerActions.setPlayerState(PlayerState.STARTED));

      restoreLocalVolumenControl();
      restoreLocalVelocityControl();
      restoreLocalPlayerErrorAction();
    },
    [
      localPlayer,
      setPlayer,
      restoreLocalVolumenControl,
      restoreLocalVelocityControl,
      restoreLocalPlayerErrorAction,
    ],
  );

  const onCastSessionError = useCallback(
    (castPlayer: FLCastPlayer) => {
      dispatch(setPlayerError({ errorMessage: t.chromecastButton_error }));
      onCastSessionEnded(castPlayer);
    },
    [onCastSessionEnded],
  );

  const { isCastDevicesAvailable, castManager } = useFLPCastManager({
    onCastSessionConnected,
    onCastSessionEnded,
    onCastSessionError,
    platformAuthorizer,
    platformClient,
  });

  const stopCastSession = useCallback(() => {
    onCastSessionEnded(player as CastPlayer);
    castManager?.destroy(true);
  }, [player, onCastSessionEnded, castManager]);

  return {
    castDeviceName,
    isCastDevicesAvailable,
    stopCastSession,
  };
};
