import mParticle from '@mparticle/web-sdk';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  GetProductsResponseItem,
  ProductServiceTypeEnum,
} from '../../../api/authApi/authApi.types';
import { buttonClicked } from '../../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { Log } from '../../../helpers/CustomLogger';
import { getImageResizeUrl } from '../../../helpers/imageResizeUrl';
import { useCompletePaymentActions } from '../../../hooks/evergent/useCompletePaymentActions';
import { useTVODOrder } from '../../../hooks/evergent/useTVODOrder';
import { useDetectCollectionPromo } from '../../../hooks/promotions/promotion-page/useCollectionPromoDetect';
import { useGetApplePayPayment } from '../../../hooks/useGetApplePayPayment';
import { useLogScreenViewedEvent } from '../../../hooks/useLogScreenViewedEvent';
import { openSubscriptionModal } from '../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../hooks/useTranslations';
import { accessTokenSelector } from '../../../store/auth';
import {
  addSubscriptions,
  addTVODOrder,
  availablePaymentOptionsSelector,
  isPurchaseLoadingSelector,
  payPalOnApproveDataSelector,
  purchaseActions,
  purchaseSelector,
  selectedPaymentOptionSelector,
} from '../../../store/purchase';
import { AppDispatch } from '../../../store/store';
import { RootState } from '../../../store/types';
import { purchaseVideoSelector } from '../../../store/videos';
import { zoneInfoStateSelector } from '../../../store/zoneInfo';
import { sendMParticleError } from '../../../utils/mParticle/sendMPArticleError';
import { payWithApplePay } from '../../../utils/payWithApplePay';
import { BackButton } from '../../BackButton/BackButton';
import { ClickEventDispatcher } from '../../ClickEventDispatcher/ClickEventDispatcher';
import { useDataZoomContext } from '../../DataZoomProvider/context';
import { SubscriptionModalBody } from '../../Modals';
import { useModalContext } from '../../Modals/context';
import PayPalPaymentModal from '../../PayPalPaymentModal/PayPalPaymentModal';
import { AvailablePaymentOptions, PaymentOptions } from '../../PaymentOptions';
import { CostSummary } from '../CostSummary/CostSummary';
import { PurchaseModal } from '../PurchaseModal';
import * as Ui from '../PurchaseModal.styles';
import { PurchaseSummary } from '../PurchaseSummary/PurchaseSummary';
import * as SelectPaymentModalUi from './SelectPaymentModal.styles';

interface Props {
  product: GetProductsResponseItem;
  onSuccess: (product: GetProductsResponseItem) => void;
  redirectBackUrl?: string;
  onSkip: () => void;
  passedOnGoBack?: () => void;
  onGoBack?: () => void;
  applyCoupon?: boolean;
  hidePurchaseZipCodeChange?: boolean;
}

const SelectPaymentModal = ({
  applyCoupon,
  product,
  onSuccess,
  onSkip,
  passedOnGoBack,
  onGoBack,
  redirectBackUrl,
  hidePurchaseZipCodeChange,
}: Props) => {
  const collectionPromo = useDetectCollectionPromo();
  const dispatch: AppDispatch = useDispatch();
  const appState = useSelector((state: RootState) => state);
  const accessToken = useSelector(accessTokenSelector);
  const { zipCode, zoneKey } = useSelector(zoneInfoStateSelector);
  const isPurchaseLoading = useSelector(isPurchaseLoadingSelector);
  const selectedPaymentOption = useSelector(selectedPaymentOptionSelector);
  const { openModal, closeModal } = useModalContext();
  const payPalOnApproveData = useSelector(payPalOnApproveDataSelector);
  const paymentOptions = useSelector(availablePaymentOptionsSelector);
  const purchaseVideo = useSelector(purchaseVideoSelector);
  const { userInfo, userSession } = useDataZoomContext();
  const { item, voucherCode } = useSelector(purchaseSelector);
  const t = useTranslations();
  const { isTVODPurchase, purchaseToken } = useTVODOrder({
    product,
  });

  useLogScreenViewedEvent('payment-method');

  const applePaymentObj = useGetApplePayPayment();
  useCompletePaymentActions({
    product,
  });

  const displayPPGSummary =
    product.serviceType !== ProductServiceTypeEnum.SVOD && purchaseVideo?.isPPGPurchasable;

  const imageUrl = displayPPGSummary
    ? getImageResizeUrl({
        assetId: purchaseVideo.id,
        format: 'jpg',
        size: '0-16x9',
      })
    : '';

  const changeSelection = useCallback(() => {
    if (collectionPromo) {
      closeModal();
      return;
    }

    openSubscriptionModal({
      content: (
        <SubscriptionModalBody
          onSuccess={onSuccess}
          onSkip={onSkip}
          redirectBackUrl={redirectBackUrl}
          onGoBack={passedOnGoBack}
        />
      ),
      openModal,
    });
  }, [onSkip, onSuccess, openModal, redirectBackUrl]);

  const handleOnGoBack = useCallback(() => {
    if (onGoBack) {
      buttonClicked({
        destination: '/home',
        location: 'Module: Subscription',
        path: window.location.pathname,
        screen: 'Select payment',
        text: 'CTA: Change plan',
        type: 'Button',
        url: window.location.href,
      });

      onGoBack();
    } else {
      changeSelection();
    }
  }, [changeSelection, onGoBack]);

  const selectPaymentOption = (option: string) => {
    dispatch(purchaseActions.selectPaymentOption(option));
  };

  useEffect(() => {
    selectPaymentOption(
      paymentOptions.includes(AvailablePaymentOptions.CREDIT_CARD)
        ? AvailablePaymentOptions.CREDIT_CARD
        : paymentOptions[0],
    );
  }, []);

  useEffect(() => {
    if (userInfo && userSession) {
      userSession.purchaseSubscription(userInfo);
    }
  }, []);

  useEffect(() => {
    if (payPalOnApproveData) {
      openSubscriptionModal({
        content: (
          <PayPalPaymentModal
            product={product}
            onSuccess={onSuccess}
            onSkip={onSkip}
            passedOnGoBack={passedOnGoBack}
            selectPaymentOnGoBack={onGoBack}
          />
        ),
        openModal,
      });
    }
  }, [payPalOnApproveData, selectedPaymentOption]);

  const handlePayWithApplePay = useCallback(
    async (payment: unknown) => {
      try {
        if (!item) {
          return;
        }

        const token = await payWithApplePay(payment);

        if (token) {
          if (isTVODPurchase) {
            dispatch(
              addTVODOrder({
                accessToken,
                assetType: purchaseVideo?.productType || '',
                paymentMethod: AvailablePaymentOptions.APPLE_PAY,
                price: item?.amountDueToday,
                product: product,
                purchaseToken: purchaseToken || '',
                txRefNo: token,
                voucherCode,
                zipCode: zipCode || '',
                zone: zoneKey || '',
              }),
            );
          } else {
            dispatch(
              addSubscriptions({
                accessToken,
                paymentMethod: AvailablePaymentOptions.APPLE_PAY,
                product: product,
                txRefNo: token,
                voucherCode,
                zipCode: zipCode || '',
                zone: zoneKey || '',
              }),
            );
          }
        }
      } catch (error) {
        Log.log('payWithApplePay error =>', error);

        sendMParticleError(
          {
            error,
            eventType: mParticle.EventType.Transaction,
          },
          appState,
        );

        dispatch(purchaseActions.setPaymentError(t.payment_error_description));
      }
    },
    [
      product,
      purchaseToken,
      zoneKey,
      item?.amountDueToday,
      zipCode,
      purchaseVideo,
      voucherCode,
      appState,
    ],
  );

  const openPaymentMethod = useCallback(async () => {
    if (!product) return;

    if (selectedPaymentOption === AvailablePaymentOptions.CREDIT_CARD) {
      openSubscriptionModal({
        content: (
          <PurchaseModal
            product={product}
            onSuccess={onSuccess}
            onSkip={onSkip}
            passedOnGoBack={passedOnGoBack}
            redirectBackUrl={redirectBackUrl}
            purchaseVideo={purchaseVideo}
            selectPaymentOnGoBack={onGoBack}
            hideZipCodeChange={hidePurchaseZipCodeChange}
          />
        ),
        openModal,
      });
    }

    if (selectedPaymentOption === AvailablePaymentOptions.APPLE_PAY) {
      await handlePayWithApplePay(applePaymentObj);
    }

    buttonClicked({
      destination: '/home',
      location: 'Module: Subscription Plan',
      path: window.location.pathname,
      screen: 'Select Payment',
      text: 'Continue',
      type: 'Button',
      url: window.location.href,
    });
  }, [
    product,
    onSuccess,
    onSkip,
    redirectBackUrl,
    purchaseVideo,
    openModal,
    dispatch,
    selectedPaymentOption,
    payPalOnApproveData,
    handlePayWithApplePay,
    applePaymentObj,
  ]);

  return (
    <Ui.ModalBody>
      <Ui.ModalContainer>
        <ClickEventDispatcher
          location='Module: Select Payment'
          screen='Select Payment'
          text='CTA: Back'
        >
          <BackButton onClick={handleOnGoBack} isFloating={false} />
        </ClickEventDispatcher>
        <Ui.ContentContainer>
          <Ui.Wrapper xs={12} md={12} xl={10} xxl={7} fullHD={6} ultraHD={4}>
            <Ui.TitleRow>
              <Ui.Title>{t.selectPaymentModal_title}</Ui.Title>
            </Ui.TitleRow>
            <PurchaseSummary
              changeSelection={changeSelection}
              imageUrl={imageUrl}
              displayPPGSummary={displayPPGSummary}
              purchaseVideo={purchaseVideo}
            />
            {displayPPGSummary && <SelectPaymentModalUi.Hr />}

            <PaymentOptions
              selectedOption={selectedPaymentOption}
              selectOption={selectPaymentOption}
            />
            <SelectPaymentModalUi.Hr />

            <CostSummary
              product={product}
              openPaymentMethod={openPaymentMethod}
              isPurchaseLoading={isPurchaseLoading}
              applyCoupon={applyCoupon}
            />
          </Ui.Wrapper>
        </Ui.ContentContainer>
      </Ui.ModalContainer>
    </Ui.ModalBody>
  );
};

export { SelectPaymentModal };
