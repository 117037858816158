import styled, { css } from 'styled-components';

import { Button as AppButton } from '../Button/Button';
import { Col, FONT_WEIGHT, TypographyProps, fonts, textBase } from '../Common';
import { BREAKPOINT } from '../ThemeProvider/constants/breakpoints';

export const ErrorContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['off-white']};
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  margin-top: 1.5rem;
  font-family: 'GothamNarrow';

  &:before {
    display: block;
    content: '';
    background-color: ${({ theme }) => theme.colors['error']};
    height: 100%;
    width: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export const ErrorLine = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${FONT_WEIGHT.Regular};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.5rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ErrorLineBold = styled(ErrorLine)`
  font-weight: ${FONT_WEIGHT.Bold};
`;

export const PasswordErrorWrapper = styled.div`
  margin-top: 1.5rem;
  &:only-child {
    margin-top: 0;
  }
`;

export const ErrorList = styled.ul<{ $displayBullets: boolean }>`
  padding: 0 1.5rem;
  margin-bottom: 0;

  ${(props) =>
    !props.$displayBullets &&
    css`
      list-style-type: none;
      padding: 0;
    `}
`;
export const ErrorListItem = styled.li`
  padding-inline-start: -0.5rem;
`;

export const Form = styled.form`

  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > div:last-child {
    flex: 1;
  }
  
  &:focus-visible {
    border: none;
    outline: none;
`;

export const FormInputCol = styled(Col).attrs({
  md: { offset: 2, span: 8 },
  xl: { offset: 3, span: 6 },
  xs: 12,
})`
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${BREAKPOINT.fullHD}px) {
    margin-left: 33.33333333%;
    flex: 0 0 auto;
    width: 33.33333333%;
  }
`;

export const ErrorWrapper = styled.div`
  min-height: 1rem;
`;

export const ButtonWrapperCol = styled(Col).attrs({
  md: { offset: 2, span: 8 },
  xl: { offset: 4, span: 4 },
  xs: 12,
})`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(AppButton)`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${FONT_WEIGHT.Bold};
  height: 3.25rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`;

export const FormTitle = styled.div<TypographyProps>`
  color: ${({ theme }) => theme.colors['pure-white']};
  ${(props) => textBase(props)};
  ${fonts.body1.xl}
  font-weight: ${FONT_WEIGHT.Bold};
  margin-bottom: 1.5rem;
  text-align: center;
`;
