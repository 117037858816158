import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';

import { useGetCurrentPathPattern } from '../../hooks/useGetCurrentPathPattern';
import { routesConfig } from '../../routes/routesConfig';
import { authSelector } from '../../store/auth';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { useSimpleModalContext } from '../Modals/SimpleModal/SimpleModalContext';
import { PrivateRoute } from '../PrivateRoute';
import { ZipCodeModalContent } from '../ZipCodeModal/ZipCodeModalContent';
import { ZipCodeValidation } from '../ZipCodeValidation';

export const RoutesProxy = () => {
  const location = useLocation();
  const { isTVERedirect } = (location.state || {}) as { isTVERedirect?: boolean };
  const { loading, loadError, zoneKey } = useSelector(zoneInfoStateSelector);
  const { isVIPUser, authed } = useSelector(authSelector);

  const loadingOrError = loading || !!loadError;
  const { openModal, closeModal } = useSimpleModalContext();

  const pathPattern = useGetCurrentPathPattern();

  const routeSettings = routesConfig[pathPattern] || {};

  const { hasZipCodeValidation, isPrivate, redirectTo, ignoreOptionalRoute } = routeSettings;

  const hasZipCodeSet = isVIPUser || isTVERedirect || zoneKey || !authed || !hasZipCodeValidation;

  useEffect(() => {
    if (!hasZipCodeSet) {
      openModal({
        content: <ZipCodeModalContent loadingOrError={loadingOrError} />,
        modalName: 'Zip code',
      });
    }

    return () => {
      closeModal();
    };
  }, [hasZipCodeSet, loadingOrError]);

  const zipCodeWrapper = (showPageContent: boolean) => {
    const content = showPageContent ? <Outlet /> : <></>;

    return hasZipCodeValidation ? <ZipCodeValidation>{content}</ZipCodeValidation> : content;
  };

  if (!routeSettings) {
    return <Outlet />;
  }

  const privateWrapper = isPrivate ? (
    <PrivateRoute
      path={pathPattern}
      redirectTo={redirectTo}
      ignoreOptionalRoute={ignoreOptionalRoute}
    >
      {zipCodeWrapper(!!hasZipCodeSet)}
    </PrivateRoute>
  ) : (
    zipCodeWrapper(!!hasZipCodeSet)
  );

  return privateWrapper;
};
