import { InfoErrorSmall } from '../../../../assets/icons/InforErrorSmall';
import { fillStringTemplate } from '../../../../helpers/fillStringTemplate';
import { useTranslations } from '../../../../hooks/useTranslations';
import * as Ui from './EmptyMonthSlot.styles';

export type EmptyMonth = {
  month: number;
  monthName: string;
  startDate: Date;
  year: number;
};

interface EmptyMonthSlotProps {
  month: EmptyMonth;
}

export const EmptyMonthSlot = ({ month }: EmptyMonthSlotProps) => {
  const t = useTranslations();
  return (
    <Ui.Row key={month.month} data-monthyear={`${month.month}-${month.year}`}>
      <InfoErrorSmall />
      <Ui.Name>
        {fillStringTemplate(t.schedule_games_noGamesRow, {
          monthName: month.monthName,
        })}
      </Ui.Name>
    </Ui.Row>
  );
};
