import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { Container } from '../../Common';
import { NAVIGATION_HEIGHT } from '../../Navigation/Navigation.styles';
import { BREAKPOINT } from '../../ThemeProvider/constants/breakpoints';
import {
  ZIP_CODE_FORM_HEIGHT_DESKTOP,
  ZIP_CODE_FORM_HEIGHT_MOBILE,
} from '../../ZipCodeForm/ZipCodeForm.styles';

export const SchedulePage = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div.attrs(() => ({
  className: 'd-flex align-items-center justify-content-center flex-grow-1',
}))``;

export const NoContentMessage = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors['pure-white']};
  text-align: center;
  font-family: 'GothamNarrow';

  font-size: 0.75rem;
  line-height: 1.1rem;

  ${breakpoint('md')`
  	font-size: 1rem;
  	line-height: 1.25; 
  `}
`;

export const ScheduleContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Navigation = styled.div`
  z-index: 2;
  background: rgba(17, 17, 17, 0.8);
  width: 100%;
  position: sticky;
  transition: all 500ms;
  top: ${({ theme }) =>
    theme.isZipBarVisible ? NAVIGATION_HEIGHT + ZIP_CODE_FORM_HEIGHT_MOBILE : NAVIGATION_HEIGHT}px;

  ${({ theme }) =>
    theme.isZipBarVisible &&
    breakpoint('lg')`
      top: ${NAVIGATION_HEIGHT + ZIP_CODE_FORM_HEIGHT_DESKTOP}px;
    `}
`;

export const NavigationContainer = styled(Container)`
  display: flex;
  flex-direction: column !important;
  gap: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  ${breakpoint('md')`
    gap: 1rem;
    padding-top: 2.5rem;
  `}
`;

export const ContentContainer = styled(Container)`
  z-index: 1;
  display: flex;
  margin: auto;
  max-width: ${BREAKPOINT.xxl}px;
  height: 100%;
  flex-grow: 1;
`;
