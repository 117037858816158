import React, { useEffect } from 'react';

import { useLogScreenViewedEvent } from '../../hooks/useLogScreenViewedEvent';
import { FirstLoadProps } from '../../routes/Routes';

const Splash: React.FC<FirstLoadProps> = ({ setFirstLoad, firstLoad }) => {
  useLogScreenViewedEvent(firstLoad ? 'splash' : undefined, !firstLoad);

  useEffect(() => {
    if (firstLoad && !(window.location.pathname == '/' || window.location.pathname == '/welcome')) {
      setFirstLoad(false);
    }
  }, []);

  return <></>;
};

export default Splash;
