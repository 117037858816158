import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../Constants/routes';
import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { AuthForm } from '../../components/AuthForm/AuthForm';
import { AuthFormFields } from '../../components/AuthForm/AuthForm.types';
import { AuthPageWrapper } from '../../components/AuthPageWrapper/AuthPageWrapper';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { AppDispatch } from '../../store/store';
import {
  forgotPassword,
  searchAccountData,
  userDataActions,
  userDataSelector,
} from '../../store/userData';
import { ForgotPasswordFormFields, forgotPasswordSchema } from '../../utils/validations';

const ForgotPassword = () => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();
  const formFields = {
    [ForgotPasswordFormFields.Username]: {
      errorOrder: 0,
      order: 0,
      placeholder: t.signUp_emailPlaceholder,
      type: 'email',
    },
  };

  const navigate = useExtendedNavigate();

  const searchAccount = ({ username }: AuthFormFields) => {
    buttonClicked({
      destination: ROUTES[RouteName.ForgotPasswordDone],
      location: 'Module: Forgot password',
      path: window.location.pathname,
      screen: 'forgot-password',
      text: `CTA: ${t.forgotPassword_button}`,
      type: 'Button',
      url: window.location.href,
    });

    dispatch(
      searchAccountData({
        email: username,
        successCallback: () => {
          dispatch(forgotPassword({ email: username }));
        },
      }),
    );
  };

  const { error, forgotPasswordLoaded } = useSelector(userDataSelector);

  useEffect(() => {
    if (!error && forgotPasswordLoaded) {
      navigate('/forgot-password-done', { replace: true });
    }
  }, [forgotPasswordLoaded, error]);

  return (
    <AuthPageWrapper
      screen='Forgot Password'
      pathname='/forgot-password'
      location='forgotPassword'
      destination='/login'
      title={t.forgotPassword_subtitle}
      previousLocation={ROUTES[RouteName.Welcome]}
    >
      <AuthForm
        onSuccess={searchAccount}
        validationSchema={forgotPasswordSchema}
        error={error}
        cleanupErrorAction={userDataActions.cleanUserDataErrors}
        displayBulletsForErrors
        formFields={formFields}
        submitButtonText={t.forgotPassword_button}
      />
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
