import { FieldValues, UseFormProps, UseFormReturn, useForm as useHookForm } from 'react-hook-form';

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props?: UseFormProps<TFieldValues, TContext>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const { register, ...restProps } = useHookForm<TFieldValues>(props);
  const { errors } = restProps.formState;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    ...restProps,
    register: (name, options) => ({
      ...register(name, options),
      hasError: !!errors[name],
    }),
  };
};
