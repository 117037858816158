import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { ROUTES, RouteName } from '../../../../Constants/routes';
import { MigrationTrigger } from '../../../../api/remoteConfigApi/remoteConfigApi.types';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { useTranslations } from '../../../../hooks/useTranslations';
import { cacheManager } from '../../../../utils';
import { getDifferenceTimeInHours } from '../../../../utils/getTimeDifferenceInHours';
import { useEnv } from '../../../EnvProvider/EnvProvider';
import { useModalContext } from '../../context';
import { MigrationModal } from '../MigrationModal';

const TRIGGERS_ON_START = [MigrationTrigger.appStart, MigrationTrigger.blockRegistration];
const LAST_TIME_MIGRATION_MODAL_SHOWS = 'lastTimeMigarationModalShows';

export const useMigrationModal = (migrationTrigger: MigrationTrigger) => {
  const { openModal, closeModal } = useModalContext();
  const t = useTranslations();
  const navigate = useExtendedNavigate();
  const { migration } = useEnv();
  const currentLocation = useLocation();

  const selectedMigration = migration[migrationTrigger];

  const openMigrationModal = () => {
    openModal(
      <MigrationModal
        {...selectedMigration}
        onClose={() => {
          if (migrationTrigger === MigrationTrigger.blockRegistration) {
            navigate(ROUTES[RouteName.Welcome]);
          }
          closeModal();
        }}
        closeBtnText={selectedMigration.closeBtnText}
        title={selectedMigration.title || t.migrationModalTitle}
        description={selectedMigration.description || t.migrationModalDescription}
      />,
      {
        contentClassName: 'migrationModalContent',
        dialogClassName: 'migrationModalDialog',
        fullscreen: 'lg-down',
      },
    );
  };

  useEffect(() => {
    if (selectedMigration) {
      if (currentLocation.pathname === ROUTES[RouteName.NewApp]) {
        return;
      }
      if (!selectedMigration.closeBtnText && migrationTrigger === 'appStart') {
        return navigate(ROUTES[RouteName.NewApp]);
      }

      if (selectedMigration?.isEnabled && TRIGGERS_ON_START.includes(migrationTrigger)) {
        if (!selectedMigration.showAlways) {
          const modalOpeningTimes = cacheManager.load(LAST_TIME_MIGRATION_MODAL_SHOWS) || {};

          // eslint-disable-next-line
          // @ts-ignore
          const lastTimeOpened = modalOpeningTimes?.[migrationTrigger];

          if (
            (lastTimeOpened &&
              getDifferenceTimeInHours(lastTimeOpened, new Date().getTime()) > 24) ||
            !lastTimeOpened
          ) {
            cacheManager.save(LAST_TIME_MIGRATION_MODAL_SHOWS, {
              [migrationTrigger]: new Date().getTime(),
            });

            return openMigrationModal();
          }
        } else {
          openMigrationModal();
        }
      }
    }
  }, []);

  return { migration: selectedMigration, openMigrationModal };
};
